
import {
  Component, Mixins, Prop, Watch,
} from 'vue-property-decorator';

import { Bar } from 'vue-chartjs';

import ExerciseService from '@/services/Exercise/ExerciseService';

const DEFAULT_COLOR = '#E03131';
const DEFAULT_COLOR_CORRECT = '#2F9E44';

@Component({
  extends: Bar,
})
export default class BarChart extends Mixins(Bar) {
  @Prop() id!: string;
  @Prop() index!: 0;
  @Prop() fileID!: number;
  @Prop() question!: Record<string, any>;
  @Prop({ default: DEFAULT_COLOR }) colorDefault!: string
  @Prop({ default: DEFAULT_COLOR_CORRECT }) colorCorrectDefault!: string

  private graphics: Record<string, any> = {};
  private chartColors = this.getColors();

  private ExerciseService = new ExerciseService();

  async mounted() {
    this.graphics = await this.ExerciseService.getExerciseGraphics(Number(this.fileID));

    if (this.question && this.$refs.canvas) {
      this.renderGraphic(this.index);
    }
  }

  get isDarkMode() {
    return this.$store.getters.isDarkMode;
  }

  @Watch('$store.getters.exerciseResult')
  async getExercices() {
    this.renderGraphic(this.index);
  }

  @Watch('index')
  onIndexChange() {
    this.renderGraphic(this.index);
  }

  getCorrect(question: Record<string, any>) {
    let i = 0;
    const barColors = [this.colorDefault, this.colorDefault, this.colorDefault, this.colorDefault, this.colorDefault];

    if (question.options) {
      question.options.forEach((item: any, index: number) => {
        if (item.correct === 1) {
          i = index;
        }
      });
    }

    barColors[i] = this.colorCorrectDefault;
    return barColors;
  }

  getLabels(index: number, graphics: Record<string, any>, question: Record<string, any>) {

    const labels: Array<any> = [];
    if (question.options && labels.length < question.options?.length) {
      for (let i = 0; i < graphics[index].options.length; i += 1) {
        labels.push(`${i + 1}`);
      }

      const completeData = (question.options.length - labels.length);
      for (let i = 0; i < completeData; i += 1) {
        labels.push(labels.length + 1);
      }
    }

    return labels;
  }

  getData(index: number, graphics: Record<string, any>, question: Record<string, any>) {
    const data: any[] = [];

    if (graphics[index].options && question.options) {
      graphics[index].options.forEach((item: any) => {
        data.push(item.percent);
      });

      if (data.length < question.options.length) {
        const completeData = (question.options.length - data.length);
        for (let i = 0; i < completeData; i += 1) {
          data.push(0);
        }
      }
    }

    return data;
  }

  @Watch('isDarkMode')
  updateColorsChart() {
    this.chartColors = this.getColors();
    this.renderGraphic(this.index);
  }

  renderGraphic(index: number) {
    this.renderChart({
      labels: this.getLabels(index, this.graphics, this.question),
      datasets: [
        {
          label: 'Exercício',
          backgroundColor: this.getCorrect(this.question),
          pointBackgroundColor: 'white',
          borderWidth: 1,
          pointBorderColor: this.chartColors.neutralColorLowPure,
          data: this.getData(index, this.graphics, this.question),
        },
      ],
    },
    {
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          usePointStyle: true,
          boxWidth: 8,
          fontStyle: '500',
          fontColor: this.chartColors.neutralColorLowPure,
          padding: 20,
        },
      },
      title: {
        display: true,
        text: `Estatística da Questão ${index + 1}`,
        fontColor: this.chartColors.neutralColorLowPure,
        fontStyle: '500',
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            fontColor: this.chartColors.neutralColorLowPure,
          },
          gridLines: {
            display: true,
            color: this.chartColors.neutralColorLowLight,
          },
        }],
        xAxes: [{
          ticks: {
            beginAtZero: true,
            fontColor: this.chartColors.neutralColorLowPure,
          },
          gridLines: {
            display: false,
          },
        }],
      },
      tooltips: {
        enabled: true,
        mode: 'single',
        callbacks: {
          label(tooltipItems) {
            return `${tooltipItems.yLabel}% da galere marcou essa alternativa`;
          },
        },
      },
    });
  }

  getColors() {
    return {
      neutralColorLowPure: getComputedStyle(document.documentElement).getPropertyValue('--neutralcolor-low-pure'),
      neutralColorLowLight: getComputedStyle(document.documentElement).getPropertyValue('--neutralcolor-low-light'),
    };
  }
}
