








import { Component, Prop, Vue } from 'vue-property-decorator';

const DEFAULT_FILL = 'var(--neutralcolor-low-pure)';
const DEFAULT_WIDTH = '22';
const DEFAULT_HEIGHT = '20';

@Component
export default class Gift extends Vue {
  @Prop({ default: DEFAULT_FILL }) fill!: string;
  @Prop({ default: DEFAULT_WIDTH }) width!: string;
  @Prop({ default: DEFAULT_HEIGHT }) height!: string;
}
