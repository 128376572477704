































































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';

import VideoThumb from '@/components/VideoThumb/index.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';
import VideoHelpResolutionLoading from '../HelpResolutionLoading/index.vue';

import PlayerVideoVimeo from '@/widgets/Video/PlayerVideo/PlayerVideoVimeo.vue';
import PlayerVideoYouTube from '@/widgets/Video/PlayerVideo/PlayerVideoYouTube.vue';

import BookService from '@/services/Book/BookService';
import HistoricService from '@/services/Historic/historicService';

import { VideosHistoric } from '@/globalInterfaces/Video';

import { VideoData, VideoQuestion } from './interface';

const COMPONENT_YOUTUBE = 'PlayerVideoYouTube';
const COMPONENT_VIMEO = 'PlayerVideoVimeo';
const VIDEO_YOUTUBE = 'youtube';
const DEFAULT_TITLE = 'Resolução em Video';

@Component({
  components: {
    VideoThumb,
    FeedbackUser,
    VideoHelpResolutionLoading,
    PlayerVideoVimeo,
    PlayerVideoYouTube,
  },
})
export default class VideoHelpResolution extends Vue {
  @Prop({ default: DEFAULT_TITLE }) title!: string;
  @Prop({ required: true }) context!: string;
  @Prop({ required: true }) questionID!: number;
  @Prop({ default: true }) isUnderlinedText!: boolean;
  @Prop({ default: true }) isShowVideo!: boolean;

  private isLoading = true;
  private showVideo = false;
  private playVideo = false;
  private video: VideoData | null = null;
  private videoID: number | null = null;
  private timeoutID: number | undefined = undefined;
  private videoHistoric: VideosHistoric | null = null;

  private BookService = new BookService();
  private HistoricService = new HistoricService();

  beforeDestroy() {
    clearTimeout(this.timeoutID);
  }

  get isVimeo() {
    if (!this.video) return '';

    if (!(this.video?.detail?.vendor && this.video?.detail?.vendor === VIDEO_YOUTUBE)) {
      return COMPONENT_VIMEO;
    }

    return COMPONENT_YOUTUBE;
  }

  get currentChecked() {
    return this.videoHistoric ? this.videoHistoric.checked : false;
  }

  @Watch('questionID', {
    immediate: true,
  })
  async getVideoIdRelatedQuestion() {
    try {
      this.resetData();

      this.isLoading = true;

      const videoQuestion = await this.BookService.getVideosQuestions({ questionID: [this.questionID] });

      this.videoID = videoQuestion?.video?.idVideo || null;

      await this.getHistoricVideo();

      this.video = await this.setVideoData(videoQuestion);
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  async getHistoricVideo() {
    if (!this.videoID) return;

    try {
      const historic = await this.HistoricService.getHistoric({
        type: 'video',
        listIds: [this.videoID],
      });

      this.videoHistoric = historic[0] || null;

    } catch (error) {
      console.error(error);
    }
  }

  async setVideoData(videoQuestion: VideoQuestion) {
    if (!videoQuestion) return null;

    const { video, discipline, topic } = videoQuestion;

    const newVideo: VideoData = {
      ...video,
      percent: this.videoHistoric?.percent || 0,
      idobject: video.idVideo,
      object: {
        title: video.title,
        context: this.context,
        path: discipline.slug,
        idTopic: String(topic.ID),
        slug: video.slug,
      },
    };

    return newVideo;
  }

  showVideoPlayer() {
    this.showVideo = true;
  }

  setPlayVideo() {
    this.timeoutID = setTimeout(() => {
      this.playVideo = true;
    }, 1500);
  }

  resetData() {
    this.showVideo = false;
    this.playVideo = false;
    this.video = null;
    this.videoHistoric = null;
    this.videoID = null;
  }

  redirectToVideo() {
    if (!this.video) return;

    const {
      context,
      path,
      idTopic,
      slug,
    } = this.video.object;

    this.$router.push({
      name: 'videos',
      params: {
        context,
        path,
        topicID: idTopic,
        fileID: String(this.video.idobject),
        slugFile: slug,
      },
    });
  }
}
