



















import {
  Component,
  Vue,
  Prop,
  Watch,
} from 'vue-property-decorator';

import { throttle } from 'lodash';

import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';

import HistoricService from '@/services/Historic/historicService';

const PLAYER_STATE_IN_PRODUCTION = 1;
const FIFTEEN_SECONDS = 15000;

@Component({
  name: 'playerYouTube',
})
export default class PlayerVideoYoutube extends Vue {
  @Prop({ required: true }) file!: Record<string, any>;
  @Prop() idTopic!: number;
  @Prop() context!: string;
  @Prop() titleTopic!: string;
  @Prop() titleBook!: string;
  @Prop() path!: string;
  @Prop() replay!: boolean;
  @Prop() isChecked!: boolean;
  @Prop({ default: false }) restart!: boolean;

  private time = 0;
  private percent = 0;
  private duration = 0;
  private timeIntervalOrder= 0;
  private intervalCurrentTimeID = 0;
  private quantityClickBtnPlay = 0;

  private forceMounted = false;

  private nextVideo: boolean | null = null;

  private player: any;

  private historicFile: Array<Record<string, any>> = [];

  private historicService = new HistoricService();

  private timeInterval = throttle(() => {
    this.setHistoric();
  }, 100);

  async mounted() {
    this.forceMounted = true;
    await this.setVideo();
  }

  destroyed() {
    this.destroyVideo();
    this.player.destroy();
  }

  destroyVideo() {
    clearInterval(this.timeIntervalOrder);
    clearInterval(this.intervalCurrentTimeID);
  }

  @Watch('file')
  async setVideo() {
    this.destroyVideo();
    this.quantityClickBtnPlay = 0;
    this.newVideo();
    this.$emit('play-video');
  }

  async newVideo() {
    const getHistoriVideo = await this.historicService.getHistoric({
      type: 'video',
      listIds: [this.file.idVideo],
    }) || [];

    this.historicFile = getHistoriVideo;

    if (this.player) {
      this.setMessageVideo();
      let duration = 0;

      if (
        !this.restart
        && this.historicFile[0]
        && this.historicFile[0].percent
      ) {
        duration = (this.file.time * this.historicFile[0].percent) / 100;
      }

      this.player.seekTo(duration);
    }

    this.timeIntervalOrder = setInterval(this.setTime, 1000);
    this.player.pauseVideo();
  }

  error() {
    this.$emit('removeMessageVideo');
    this.$emit('setMessageVideo', 'error');
  }

  ready(video: Record<string, any>) {
    this.player = video;
    let duration = 0;
    this.setMessageVideo();

    if (
      !this.restart
      && this.historicFile[0]
      && this.historicFile[0].percent
    ) {
      duration = (this.file.time * this.historicFile[0].percent) / 100;
    }
    this.player.seekTo(duration);
    this.player.pauseVideo();
    this.setTrackAmplitude();
  }

  playing() {
    this.setTimeToSaveHistoric();
    this.bntClickForNextVideo();
  }

  ended() {
    this.percent = 100;
    this.setHistoric();
  }

  setTimeToSaveHistoric() {
    this.intervalCurrentTimeID = setInterval(() => {
      const playerState = this.player.getPlayerState();

      if (playerState === PLAYER_STATE_IN_PRODUCTION) {
        this.timeInterval();
      }

    }, FIFTEEN_SECONDS);
  }

  async setHistoric() {
    try {
      if (!this.time) return;

      const videoDate = {
        idobject: this.file.idVideo,
        type: 'video',
        percent: this.percent,
        checked: this.isChecked,
        object: {
          thumb: this.file.detail?.thumb?.length
            && this.file.detail?.thumb[3]
            && this.file.detail?.thumb[3].link,
          slug: this.file.slug,
          idTopic: String(this?.idTopic),
          title: this.file.title,
          titleTopic: this.titleTopic,
          titleBook: this.titleBook,
          time: this.file.time,
          book: this.path,
          context: this.context,
          path: this.path,
        },
      };

      await this.historicService.saveHistoric(videoDate);

      this.$store.commit('setOrder', { video: this.percent, time: this.time });
    } catch (error) {
      console.error(error);
    }
  }

  async setTime() {
    if (!this.player) return;

    this.time = await this.player.getCurrentTime();
    this.duration = await this.player.getDuration();
    this.percent = (this.time * 100) / this.duration;

    this.$store.commit('setOrder', { video: this.percent, time: this.time });
  }

  setMessageVideo() {
    if (this.historicFile[0]
      && this.historicFile[0].percent
      && this.historicFile[0].percent === 100
    ) {
      this.nextVideo = false;
      this.$emit('setMessageVideo', 'replay');

    } else if (this.historicFile[0]
      && this.historicFile[0].checked
      && this.historicFile[0].percent !== 100
    ) {
      this.$emit('setMessageVideo', 'continue');

    } else {
      this.nextVideo = true;
    }
  }

  setTrackAmplitude() {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'PlayerVideoYoutube',
          local: 'Player Vídeo',
        },
      },
    });
  }

  bntClickForNextVideo() {
    // this.$emit('video-viewed', this.percent);

    this.quantityClickBtnPlay += 1;

    if (this.quantityClickBtnPlay >= 2) {
      this.nextVideo = true;
    }
  }

  @Watch('percent')
  setEmitPercent() {
    if (this.nextVideo && this.percent && this.percent === 100) {
      this.$emit('nextVideo');
    }
  }

  @Watch('replay')
  replayVideo() {
    if (this.player) this.player.playVideo();
  }
}

