export const DESCRIPTIONS = {
  allCorrect: [
    'Excelente desempenho! Você acertou todas as perguntas com maestria!',
    'Incrível! Todas as respostas estão corretas. Parabéns pela excelência!',
    'Sensacional! Nenhuma questão ficou sem resposta certa. Parabéns pela perfeição!',
    'Fantástico! Você atingiu a pontuação máxima, sem cometer nenhum equívoco!',
    'Espetacular! Todas as suas escolhas foram precisas. Parabéns pela nota máxima!',
    'Impressionante! Não há erros em seu desempenho. Parabéns pela conquista!',
    'Extraordinário! Todas as respostas estão corretas. Você arrasou!',
    'Bravo! Nenhuma resposta incorreta. Você realmente se destacou!',
    'Magnífico! Cada resposta foi impecável. Parabéns pelo resultado perfeito!',
    'Estupendo! Todas as questões foram respondidas corretamente. Parabéns pela excelência!',
  ],
  someCorrect: [
    'Bom trabalho no exercício! Continue praticando para aprimorar seus conhecimentos.',
    'Parabéns pela tentativa! Pratique mais e veja ainda mais progresso da próxima vez.',
    'Ótimo esforço no exercício! A prática frequente levará a resultados cada vez melhores.',
    'Parabéns pela participação! Pratique regularmente para alcançar um desempenho ainda melhor.',
    'Bom desempenho no exercício! Não desanime, pratique mais para alcançar excelência.',
    'Parabéns pela dedicação! A prática constante é a chave para o sucesso.',
    'Boa tentativa! Continue praticando para superar desafios futuros.',
    'Você está no caminho certo! Pratique mais para aprimorar suas habilidades.',
    'Parabéns pela persistência! Pratique regularmente para alcançar seus objetivos.',
    'Bom esforço! Praticar com consistência é o segredo do progresso contínuo.',
  ],
};
