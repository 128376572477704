














































import { Component, Vue, Prop } from 'vue-property-decorator';

const DEFAULT_FILL = '#FFC100';
const DEFAULT_WIDTH = '24';
const DEFAULT_HEIGHT = '24';

@Component
export default class Stars extends Vue {
  @Prop({ default: DEFAULT_FILL }) fill!: string;
  @Prop({ default: DEFAULT_WIDTH }) width!: string;
  @Prop({ default: DEFAULT_HEIGHT }) height!: string;
}
