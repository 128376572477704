


























































































import { Component, Prop, Vue } from 'vue-property-decorator';

import FeedbackUser from '@/components/FeedbackUser/index.vue';

import HelpResolutionLoading from '../HelpResolutionLoading/index.vue';

import { ALPHABET, TYPE_RESOLUTION } from '@/constant/Question';

import { optimizeImageLoadingInAlternative, replaceText } from '@/share/Util/Text/index';

interface Resolution {
  content: string;
}

@Component({
  components: {
    FeedbackUser,
    HelpResolutionLoading,
  },
})
export default class HelpResolution extends Vue {
  @Prop({ default: 'Resolução em Texto' }) title!: string;
  @Prop({ default: true }) isTitle!: boolean;
  @Prop({ required: false }) backgroundColor!: string;
  @Prop({ required: false }) text!: string;
  @Prop({ default: true }) isUnderlinedText!: string;
  @Prop({ default: true }) isOverflow!: string;
  @Prop({ default: false }) isAnimation!: boolean;
  @Prop({ default: true }) isLoading!: boolean;
  @Prop({ required: false }) listResolution!: Resolution[];
  @Prop({ default: false }) showResolutionAnswers!: boolean;
  @Prop({ default: false }) typeResolution!: number;
  @Prop({ default: true }) showContent!: boolean;

  get content() {
    return this.showContent ? this.text : replaceText(this.text);
  }

  hasContentHTML(content: string) {
    return content === '<p><br></p>' || content === '<p></p>'
      ? false
      : !!content;
  }

  optimizeImage(text: string) {
    return optimizeImageLoadingInAlternative(text);
  }

  setAlternativeNumber(position: number) {
    switch (this.typeResolution) {
      case TYPE_RESOLUTION.SUMMATION:
        return this.setAlternativeNumberSummation(position);
      case TYPE_RESOLUTION.RELATE_COLUMNS:
        return this.setAlternativeNumberRelateColumns(position);
      default:
        return this.setAlternativeNumberAlphabet(position);
    }
  }

  setAlternativeNumberAlphabet(position: number) {
    return ALPHABET[position];
  }

  setAlternativeNumberSummation(position: number) {
    if (position === 0 || position === 1) return position + 1;

    return 2 ** position;
  }

  setAlternativeNumberRelateColumns(position: number) {
    return position + 1;
  }
}
